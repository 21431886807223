/**
 * External Dependencies
 */

 import 'jquery';
 import Swiper from 'swiper/bundle';
 var $ = require('jquery');
 var Masonry = require('masonry-layout');
 var imagesLoaded = require('imagesloaded');
 import * as basicScroll from 'basicscroll';
 import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';
 import "@lottiefiles/lottie-player";

 $(document).ready(function() {
  // // CUSTOM CURSOR
  // const cursor = document.querySelector('.cursor');
  // $(document).mousemove(function(d){
  //   cursor.style.top = d.clientY + 'px';
  //   cursor.style.left = d.clientX + 'px';
  // });

  // init Masonry
  if ( $('.images__grid').length) {
    var grid = document.querySelector('.images__grid');

    var msnry = new Masonry( grid, {
      itemSelector: '.images__item',
    });

    imagesLoaded( grid ).on( 'progress', function() {
      msnry.layout();
    });
  }

  $('.swiper-container, .swiper-images, .services__loop, .imageswiper-container, .swiper-services').mouseover(
    function() {
      $('body').attr('data-cursor', 'drag');
    }
  ).mouseleave(
    function() {
      $('body').attr('data-cursor', 'normal');
    }
  );

  var Swipes = new Swiper('.swiper-container', {
    loop: false,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 24
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 24
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 32
      },
    },
    parallax: true,
    preloadImages: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: '.pagination',
      type: 'bullets',
      clickable: true,
    },
  });

  var Swipes = new Swiper('.swiper-images', {
    effect: 'cards',
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop: false,
    breakpoints: {
      320: {
        slidesPerView: 1
      },
    },
    preloadImages: true,
  });

  var swiper = new Swiper(".swiper--slider", {
    effect: "cards",
    grabCursor: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  var Swipes = new Swiper('.swiper-services', {
    loop: false,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 16
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 24
      },
    },
    parallax: true,
    preloadImages: true,
    pagination: {
      el: '.pagination',
      type: 'bullets',
      clickable: true,
    },
  });

  var Swipes = new Swiper('.imageswiper-container', {
    loop: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 0
      },
    },
    parallax: true,
    preloadImages: true,
    pagination: {
      el: '.pagination',
      type: 'bullets',
      clickable: true,
    },
  });

  // TABNAV
  if($('.js-tabnav-tab').length) {
    (function($) {
      $.fn.tabnav = function tabnav(userConfig) {
        var config = $.extend({
          activeTabClass: 'active',
          activePanelClass: 'active',
        }, userConfig);

        var $wrapper = $(this);
        var $tabs = $wrapper.find('.js-tabnav-tab');
        var $panels = $wrapper.find('.js-tabnav-panel');
        var $panelsWrapper = $wrapper.find('.js-tabnav-panels');
        var $activeTab;
        var $activePanel;
        var activeTab = 0;

        console.log($activeTab);
        console.log(activeTab);

        $panels
        .attr('role', 'tabpanel')
        .attr('aria-hidden', 'true');

        $tabs
        .attr('role', 'tab')
        .attr('aria-selected', 'false')
        .attr('aria-setsize', $tabs.length)
        .attr('tabindex', 0)
        .each(function(i, e) {
          var $elem = $(e);
          if ($elem.hasClass(config.activeTabClass)) {
            setActiveTab(i);
          }
          $elem.attr('aria-posinset', i + 1);
        })
        .click(setTabs)
        .keypress(setTabs);

        function setActiveTab(tabPos) {
          $activeTab = $($tabs.get(tabPos))
          .addClass(config.activeTabClass)
          .attr('aria-selected', 'true');

          $activePanel = $($panels.get(tabPos))
          .addClass(config.activePanelClass)
          .attr('aria-hidden', 'false');

          $panelsWrapper.css('height', $activePanel.height() + 'px');
        }

        function setTabs(e) {
          if (e.type === 'keypress' && e.keyCode !== 13) {
            return;
          }

          var $elem = $(e.currentTarget);

          var tabPos = Number($elem.attr('aria-posinset')) - 1;
          $tabs
          .removeClass(config.activeTabClass)
          .attr('aria-selected', 'false');
          $panels
          .removeClass(config.activePanelClass)
          .attr('aria-hidden', 'true');

          setActiveTab(tabPos);
        }

        $(window).resize(function onWindowResize() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });

        $(window).load(function() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });
      }

      var tabnav = $('.tabnav');
      if (tabnav.length != 0) {
        $(tabnav).each(function(){
          $(this).tabnav();
          console.log('ok')
        });
      }
    }(jQuery));
  }

  // Swipes.on( 'sliderMove', function( event ) {
  //   cursor.style.top = event.originalEvent.y + 'px';
  //   cursor.style.left = event.originalEvent.x + 'px';
  // });

  // MENU IMAGE
  var menuId = $('.fixednav li.current_page_item').prop('id');
  $(`.menu-image[data-id=${menuId}]`).addClass('active');

  $('.fixednav li').hover(
    function () {
      var menuId = $(this).prop('id');
      $('.menu-image').removeClass('active');
      $(`.menu-image[data-id=${menuId}]`).addClass('active');
    },
    function () {
      var menuId = $('.fixednav li.current_page_item').prop('id');
      $('.menu-image').removeClass('active');
      $(`.menu-image[data-id=${menuId}]`).addClass('active');
    }
  );

  // MENU IMAGE
  $('.services').each(function() {
    $(this).find('li').hover(
      function () {
        var serviceId = $(this).prop('id');
        var parent = $(this).closest('.services');
        $('.services__image', parent).removeClass('active');
        $(`.services__image[data-id=${serviceId}]`, parent).addClass('active');
      },
      function () {
        var serviceId = $(this).prop('id');
        var parent = $(this).closest('.services');
        $('.services__image', parent).removeClass('active');
        $(`.services__image[data-id=${serviceId}]`, parent).addClass('active');
      }
    );
  });

  // SCROLLED NAV
  $(window).on('load scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });
  // HAMBURGER
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.close-nav').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  setTimeout(function() {
    document.querySelectorAll('.basic-image').forEach((elem) => {
      basicScroll.create({
        elem: elem,
        from: 'center-center',
        to: 'bottom-bottom',
        direct: true,
        props: {
          '--scaleX': {
            from: '1',
            to: '1.5',
          },
          '--scaleY': {
            from: '1',
            to: '1.5',
          },
        },
      }).start()
    })

    // document.querySelectorAll('.basic-opacity').forEach((elem) => {
    //   basicScroll.create({
    //     elem: elem,
    //     from: 'center-center',
    //     to: 'bottom-bottom',
    //     direct: true,
    //     props: {
    //       '--opacity': {
    //         from: '0.4',
    //         to: '0.8',
    //       },
    //     },
    //   }).start()
    // })

    document.querySelectorAll('.move-top').forEach((elem) => {
      basicScroll.create({
        elem: elem,
        from: 'bottom-bottom',
        to: 'top-top',
        direct: true,
        props: {
          '--translateY': {
            from: '0',
            to: '-50%',
          },
        },
      }).start()
    })

    document.querySelectorAll('.move-left').forEach((elem) => {
      basicScroll.create({
        elem: elem,
        from: 'bottom-bottom',
        to: 'top-top',
        direct: true,
        props: {
          '--translateX': {
            from: '0',
            to: '-100%',
          },
        },
      }).start()
    })

    document.querySelectorAll('.move-right').forEach((elem) => {
      basicScroll.create({
        elem: elem,
        from: 'bottom-bottom',
        to: 'top-top',
        direct: true,
        props: {
          '--translateX': {
            from: '0',
            to: '100%',
          },
        },
      }).start()
    })
}, 150);

// active images in viewport
  var active_images = 4;

  // time image change
  var fader_delay   = 3000;

  // setup image urls
  var images = [];

  // read img from html
  $('#logofader img').each(function(index) {

    // add src to array
    // images.push($( this ).attr('src'))
    images.push($(this).data("src") );

    // remove from dom
    $( this ).remove();
  });

  // add initial active images to active array
  var images_active = images.splice(0, active_images);
  // add initial images to viewport
  for (var i = 0; i < images_active.length; i++) {
    $('#logofader').prepend($(`
      <div class="brand w-full relative">
          <div class="aspect-video"></div>
          <div class="ease-in-out duration-300 absolute inset-0 flex items-center justify-center">
            <div class="p-4 lg:p-8 xl:p-12 w-full">
              <img class="brand__image w-full h-full object-contain z-10 relative max-h-[4rem] max-w-[10rem] mx-auto" src="${images_active[i]}">
            </div>
          </div>
        </div>
    `));
  }

  // var loop = runLoop();

  // function runLoop() {
  //   return window.setInterval(function(){
  //     // get random active image from array
  //     var randomActive = images_active[Math.floor(Math.random()*images_active.length)];

  //     // get random hidden image from array
  //     var randomHidden = images[Math.floor(Math.random()*images.length)];


  //     // loop trough active images
  //     $('#logofader img').each(function(index) {

  //       // find the random image
  //       if($( this ).prop('src') == randomActive) {

  //         // replace the src with the new url
  //         $(this).fadeOut(400, function() {
  //             $( this ).prop('src', randomHidden);
  //         })

  //         .fadeIn(400);
  //         // $( this ).attr('src', randomHidden);

  //       }
  //     });

  //     // call swapimage for keeping arrays up to date
  //     swapImage(randomActive, randomHidden);
  //   }, fader_delay);
  // }


  // function swapImage(urlActive, urlHidden) {


  //   // find active url and remove it from array
  //   images_active = images_active.filter((element) => {
  //     if(element != urlActive)
  //       return true
  //     else
  //       return false
  //   });

  //   // find hidden url and remove it from array
  //   images = images.filter((element) => {
  //     if(element != urlHidden)
  //       return true
  //     else
  //       return false
  //   });

  //   // add urls to arrays
  //   images.push(urlActive);
  //   images_active.push(urlHidden);

  // }

  // window.addEventListener('focus', function() {
  //   // browser active do loop
  //   loop = runLoop();
  // },false);

  // window.addEventListener('blur', function() {
  //     // browser gone stop loop
  //     window.clearInterval(loop);
  // },false);

  // PROJECTS FILTER
  $('.projects-filter__cat-filter').change(handleFilter);
  $('.projects-filter__subcat-filter').change(handleFilters);

  function handleFilters () {
    var uristring = '?category=';
    $('.projects-filter__cat-filter:checked').each(function(index) {
      var isLastElement = index == $('.projects-filter__cat-filter:checked').length -1;
      uristring += $(this).val();
    });

    uristring += '&subcategory=';
    $('.projects-filter__subcat-filter option:checked').each(function(index) {
      var isLastElement = index == $('.projects-filter__subcat-filter option:checked').length -1;
      uristring += $(this).val();
    });
    window.location.href = uristring;
  }

  function handleFilter () {
    var uristring = '?category=';
    $('.projects-filter__cat-filter:checked').each(function(index) {
      var isLastElement = index == $('.projects-filter__cat-filter:checked').length -1;
      uristring += $(this).val();
    });
    uristring += '&subcategory=';
    window.location.href = uristring;
  }

  // NEWS FILTER
  $('.news-checkbox').change(handleNewsFilter);

  function handleNewsFilter () {

    var uristring = '?category=';

    $('.news-checkbox:checked').each(function(index) {
      var isLastElement = index == $('.news-checkbox:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  if ($('#projects').length) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('category')) {
      $('html, body').animate({
        scrollTop: $("#projects").offset().top
      }, 0);
    }

    const queryStringProjects = window.location.search;
    const urlParamsProjects = new URLSearchParams(queryStringProjects);
    if (urlParamsProjects.get('pag')) {
      $('html, body').animate({
        scrollTop: $("#projects").offset().top
      }, 0);
    }
  }

  // INTRO
  if ($(window).width() > 640) {
    var visits = localStorage.getItem('visits');

    if (localStorage) {
      if (visits == null) {
        visits = 1
      }
      if (visits == 1) {
        $('#header-animated').show();
        $('body').addClass('intro');
      }
      else {
        $('#header-static').show();
      }
      localStorage.setItem('visits', visits + 1);
    }
  }

  // ADD SPAN TO BASIC BUTTON
  jQuery('.wp-block-button__link').wrapInner('<span></span>');

  // COMPANY PAGE
  const sections = document.querySelectorAll(".history__year");
  const dots = document.querySelectorAll(".history__dot");
  const navLi = document.querySelectorAll(".history__image");

  if ( sections.length) {
    window.onscroll = () => {
      var current = "";

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        section.classList.remove('active');
        if (pageYOffset >= sectionTop - 150) {
          current = section.getAttribute("id");
          if (current) {
            section.classList.add('active');
          }
        }
      });

      dots.forEach((dot) => {
        const dotTop = dot.offsetTop;
        dot.classList.remove('active');
        if (pageYOffset >= dotTop - 150) {
          dot.classList.add('active');
        }
      });

      navLi.forEach((li) => {
        li.classList.remove("active");
        if (li.classList.contains(current)) {
          li.classList.add("active");
        }
      });

      if(current === '') {
        var requiredElement = sections[0];
        requiredElement.classList.add('active');

        var requiredElementA = navLi[0];
        requiredElementA.classList.add('active');
      }
    };
  };

  // Video Block
  $('#playButton').on('click', function() {
    $('#video').trigger('play');
    $('#video_placeholder').fadeOut();
    $('#placeholder_overlay').fadeOut();
    $('#playButton').fadeOut();
    $('#video').fadeIn();
  });
});
